import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useMakeSelector } from 'hooks/use-make-selector';
import {
  makeGetCategoryParentInfoByProductId,
  makeGetProductById,
} from 'redux/modules/entities/selectors/products';
import { getLocation, isSuppressAlternatives } from 'redux/modules/routing/selectors';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { isSponsoredProduct } from 'redux/modules/search-and-browse/selectors';
import { getPageType } from 'utils/get-page-type';
import { getIsProductBoosted } from 'redux/modules/favourites/selectors/get-is-product-boosted';
import { useExperiments } from 'components/Experiment/useExperiments';
import { POD_VIEW_ALTERNATIVES, BUILD_VIEW_ALTERNATIVES } from 'constants/monetateExperiments';
import { swapUnavailableModal } from 'constants/modals';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import { notifyModalOpen } from 'redux/modules/common-modal/actions';
import { toggleSwapUnavailableProductModal } from 'redux/modules/trolley/actions/toggle-swap-unavailable-product-modal';
import { getSubstitutionProducts } from 'redux/modules/trolley/actions/get-substitution-products';

import { useSelector, useDispatch } from 'react-redux';
import { handleProductListClick } from 'utils/gtm';

export interface ViewAlternativesProps {
  productId: string;
  conflictMessage: string;
  position: number;
}

export const trackViewAlternativesClick =
  (
    productData: unknown,
    analyticsData: {
      isFavourite: boolean;
      position: number;
      sponsored: boolean;
      sponsorshipId: string;
      conflictMessage: string;
      wrapperId: string;
      boosted: boolean;
    },
  ) =>
  () => {
    handleProductListClick({
      conflictMessage: analyticsData?.conflictMessage?.toLowerCase(),
      isFavourite: analyticsData?.isFavourite,
      productData,
      searchType: analyticsData?.wrapperId,
      wrapperId: analyticsData?.wrapperId,
      position: analyticsData?.position,
      boosted: analyticsData?.boosted,
      sponsored: analyticsData?.sponsored,
      // @ts-expect-error handleProductListClick() has not been typed
      sponsorshipId: analyticsData?.sponsorshipId,
      viewAlternativesClick: true,
    });
  };

const ViewAlternatives = ({ productId, conflictMessage, position }: ViewAlternativesProps) => {
  const dispatch: WtrDispatch = useDispatch();
  const { id, url } = useMakeSelector(makeGetCategoryParentInfoByProductId, productId);
  const { pathname } = useSelector(getLocation);
  const suppressDisplayLink = useSelector(isSuppressAlternatives);
  const productData = useMakeSelector(makeGetProductById, productId);
  const { lineNumber, sponsorshipId } = productData;
  const analyticsData = {
    // eslint-disable-next-line
    // @ts-ignore
    isFavourite: useSelector(state => getIsFavourite(state, lineNumber)),
    position,
    // eslint-disable-next-line
    // @ts-ignore
    sponsored: useSelector(state => isSponsoredProduct(state, lineNumber)),
    sponsorshipId,
    conflictMessage,
    wrapperId: getPageType(pathname),
    // eslint-disable-next-line
    // @ts-ignore
    boosted: useSelector(state => getIsProductBoosted(state, lineNumber)),
  };

  const display = id && pathname !== url && !suppressDisplayLink;

  const { getDecisionById } = useExperiments();
  const viewSimilar =
    getDecisionById(POD_VIEW_ALTERNATIVES.experiment).variant ===
    POD_VIEW_ALTERNATIVES.variant.viewSimilar;
  const toggleAlternativesModal =
    getDecisionById(BUILD_VIEW_ALTERNATIVES.experiment).variant ===
    BUILD_VIEW_ALTERNATIVES.variant.toggleModal;

  const VIEW_ALTERNATIVES_LABEL = viewSimilar ? 'View similar' : 'View alternatives';

  // Event handlers to be updated in https://www.jlpit.com/jira/browse/WPIP-64887
  const getAlternateProductsCall = useCallback(
    (productLineNumber, origin) => dispatch(getSubstitutionProducts(productLineNumber, origin)),
    [dispatch],
  );
  const toggleModal = useCallback(
    (productLineNumber, isOpen) =>
      dispatch(toggleSwapUnavailableProductModal(productLineNumber, null, isOpen)),
    [dispatch],
  );
  const notifyModalCall = useCallback(props => dispatch(notifyModalOpen(props)), [dispatch]);

  const handleButtonClick = () => {
    toggleModal(lineNumber, true);
    getAlternateProductsCall(lineNumber, 'view-alternatives');
    notifyModalCall({
      ...swapUnavailableModal,
      title: swapUnavailableModal.titleText,
    });
    // TODO: Confirm tracking that needs to be done
    // dispatch(trackViewAlternativesClick(productData, analyticsData));
  };

  if (!display) {
    return null;
  }

  return (
    <>
      {toggleAlternativesModal ? (
        <TextLink
          component="button"
          onClick={handleButtonClick}
          data-testid="view-alternatives-toggle"
        >
          {VIEW_ALTERNATIVES_LABEL}
        </TextLink>
      ) : (
        <TextLink
          to={url}
          component={Link}
          onClick={trackViewAlternativesClick(productData, analyticsData)}
          data-testid="view-alternatives-link"
        >
          {VIEW_ALTERNATIVES_LABEL}
        </TextLink>
      )}
    </>
  );
};

export default ViewAlternatives;
